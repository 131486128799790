.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#navBar{
  list-style:none;
  margin-top:0;
  margin-bottom:0;
  padding-top:10px;
  padding-bottom:10px;
  border-bottom: 1px solid black;
}
#navBar li{
  display:inline;
  margin-right:20px;
}
#navBar li a{
  text-decoration: none;
  color:black;
}
body{

}
#pageBody{
  padding:20px;
}

#appContainer > hr{
  margin-right:-20px;
  margin-left:-20px;
}
